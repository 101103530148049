import React from "react"
import { Link } from "gatsby"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TacticalImg from "../components/playerImage"
import AffPes from "../components/pes2021cta"

const bestPlayers = () => {
  return (
    <Layout>
      <SEO
        title="Best Gold, Silver and Bronze Players"
        description="Find out who the best Gold, Silver and Bronze Balls are in PES 2020 myClub."
      />
      <div className="container">
        <h1>The best Gold, Silver and Bronze Players in PES 2020</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/best-players-ball-type/">Best Players</Link>
          </li>
          <li>Best Players in PES 2020</li>
        </ul>
        <p>
          These are my favorite players in PES 2020. They are divided by Ball
          Type: Gold, Silver, and Bronze.
        </p>
        <p>
          I'm not listing these players based on their training potential, or
          Max Overall. I have used all of them in dozen of games, online and VS
          COM, and they fit my gamestyle perfectly.
        </p>
        <p>
          I have included pictures of all of them, so that you can have a look
          at their stats. Some have completed their training, some haven't, but
          at least you will have a grasp of their areas of expertise.
        </p>
        <p>
          Have a look at <Link to="/player-attributes/">Player Attributes</Link>
          , <Link to="/player-skills/">Player Skills</Link>, and{" "}
          <Link to="/playing-styles/">Playing Styles</Link> if something is
          unclear to you.
        </p>
        <p>
          This is a very subjective matter - you probably have different players
          you enjoy. But I hope you find a gem or two in this list!
        </p>
      </div>
      <AffPes />
      <nav id="toc">
        <div className="container">
          <span>Table of Contents</span>
          <ul>
            <li>
              <AnchorLink href="#bestgoldball" offset="70">
                Best Gold Ball Players
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#bestsilverball" offset="70">
                Best Silver Ball Players
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#bestbronzeball" offset="70">
                Best Bronze Ball Players
              </AnchorLink>
            </li>
          </ul>
        </div>
      </nav>
      <div className="container">
        <h2 id="bestgoldball">Best Gold Ball Players</h2>
        <h3 className="bullet">Éder Militão (Real Madrid)</h3>
        <TacticalImg
          src={`eder-militao.jpg`}
          alt={`Éder Militão in PES 2020 myClub`}
        />
        <p>
          I only use CBs with the "Build Up" playing style, and Militão was one
          of the first I got. He hasn't left my team since.
        </p>
        <p>
          Apart from Virgil van Dijk, I feel that this is the best CB in the
          game. He is a tall, aggressive defender with high "Defensive
          Awareness", probably the most important stat.
        </p>
        <p>
          Despite his size, he is quite fast, which makes him even more useful,
          since you can use him as a RB as well.
        </p>
        <p>
          You get 2 for 1 with Militão, and his high form means he will be very
          consistent even on C-Condition weeks.
        </p>
        <h3 className="bullet">Joe Gomez (Liverpool)</h3>
        <TacticalImg src={`j-gomez.jpg`} alt={`J. Gomez in PES 2020 myClub`} />
        <p>
          I like Defensive Full-backs, and with Joe Gomez you get 3 for 1: a
          solid CB that can play perfectly as a RB or LB.
        </p>
        <p>
          Gomez is a very well-rounded player, with good defensive stats overall
          and an unbelievable speed for a defender.
        </p>
        <p>
          He is my RB of choice, along with Bissaka, also listed below. I wish
          his Form was higher, but he is (at minimum) a great option to have on
          the bench, as he can play any position on the defense.
        </p>
        <h3 className="bullet">Wilfried Zaha (Crystal Palace)</h3>
        <TacticalImg
          src={`w-zaha.jpg`}
          alt={`Wilfried Zaha in PES 2020 myClub`}
        />
        <p>
          I like pacey wingers with the "Prolific Winger" playing style, and
          Zaha was one of my favorite players in PES 2019. I scouted him as soon
          as I bought PES 2020 and he hasn't disappointed yet.
        </p>
        <p>
          Zaha is famous for his dribbling and he has the "Flip Flap" skill,
          which I enjoy using to send my opponents the wrong way.
        </p>
        <p>
          His "Finishing" and "Physical Contact" stats look average on paper,
          but he is a very clinical player inside the box, as long as you know
          what you are doing. I find him pretty strong as well, even when pushed
          by strong defenders.
        </p>
        <h3 className="bullet">João Félix (Atlético Madrid)</h3>
        <TacticalImg
          src={`joao-felix.jpg`}
          alt={`João Félix in PES 2020 myClub`}
        />
        <p>
          Portugal's golden boy is still adapting to the Spanish League, but in
          the game he is one of the most promising players, at just 20 years of
          age and 48 levels to train.
        </p>
        <p>
          João Félix should be used as a "SS" or "AMF" in your team. He's better
          at dribbling and passing than running, so I prefer using him in the
          midfield.
        </p>
        <p>
          This trickster has pretty much every feint you can think of, and the
          "Gamesmanship" skill means he will win many fouls if he loses the ball
          (which can be infuriating when facing him).
        </p>
        <h3 className="bullet">Vinícius Júnior (Real Madrid)</h3>
        <TacticalImg
          src={`vinicius-junior.jpg`}
          alt={`Vinícius Júnior in PES 2020 myClub`}
        />
        <p>
          Vinícius was one of the most promising players in PES 2019, and this
          year he has been upgraded from Silver to Gold, preserving his "Roaming
          Flank" playing style.
        </p>
        <p>
          This youngster can be defined in 2 words: pace and dribbling. He has
          pretty much every skill you can imagine, like "Double Touch", "Flip
          Flap", "Sombrero" or "Step on Skill Control", so if you like Skill
          Moves, you are looking at a cheap Neymar here.
        </p>
        <p>
          Vinícius Júnior is only 19 years at age, so he has a lot of potential.
          Fully trained, his max overall rating reaches 93, with 99 at dribbling
          and incredible speed and acceleration.
        </p>
        <p>
          If you look at the stats, it will look as if his "Finishing" is a
          letdown - however, I have scored hat-tricks with him online on
          A-condition weeks.
        </p>
        <p>
          He has been performing well for Real Madrid this season, so hopefully
          he will keep up scoring bangers for me!
        </p>
        <h3 className="bullet">Jonathan Tah (Bayer Leverkusen)</h3>
        <TacticalImg
          src={`j-tah.jpg`}
          alt={`Jonathan Tah in PES 2020 myClub`}
        />
        <p>
          A very tall, physical defender with decent pace and a good weak foot
          to build up from the back.
        </p>
        <p>
          A solid alternative to Virgil van Dijk, Tah is a great solution if
          you're facing dominant forwards like Lukaku or Zlatan Ibrahimović.
          Just don't use him as a RB!
        </p>
        <h3 className="bullet">Kingsley Coman (FC Bayern Munich)</h3>
        <TacticalImg
          src={`k-coman.jpg`}
          alt={`Kingsley Coman in PES 2020 myClub`}
        />
        <p>
          Bayern has some great wingers and they all have potential. Coman is a
          good example of this, at just 23 years of age.
        </p>
        <p>
          The Frenchman can play on either wing and has amazing speed and
          dribbling. But to me, the deciding factor is that he possesses both
          "Super-sub" and "Double Touch" skills - making him a game-changer when
          he appears fresh in the second half.
        </p>
        <p>
          If you are willing to fully train him, you will have a winger with 99
          on both "Speed" and "Acceleration".
        </p>
        <p>
          I wish his "Offensive Awareness" was higher, but coming on as a sub
          against tired defenders, he will definitely make a difference.
        </p>
        <h3 className="bullet">Luka Jović (Real Madrid)</h3>
        <TacticalImg
          src={`l-jovic.jpg`}
          alt={`Luka Jović in PES 2020 myClub`}
        />
        <p>
          In my opinion, the best Gold Ball player with the "Fox in the Box"
          playstyle. Since that is my preferred Playing Style for a CF, Jovic is
          a striker I enjoy using, especially coming off the bench, since he has
          the "Super-sub" skill.
        </p>
        <p>
          Jović has all the right attributes of a good CF. Speed, Dribbling, and
          Physical stats are all well balanced. "Offensive Awareness" and
          "Finishing" is where he excels at, and that "First-time Shot" skill
          comes in handy after a deadly pass.
        </p>
        <p>
          If you are willing to train him all 40 levels, you will get a 93-rated
          Gold Ball player.
        </p>
        <h3 className="bullet">Cristian Pavón (Argentina - National Team)</h3>
        <TacticalImg
          src={`c-pavon.jpg`}
          alt={`Cristian Pavón in PES 2020 myClub`}
        />
        <p>
          Pavón was one of my favorite wingers in last year's edition, due to
          his insane combination of speed and dribbling. I scouted him fairly
          early after buying PES 2020.
        </p>
        <p>
          His dribbling doesn't feel as impressive this year, but I never felt
          held back. He has the "Double Touch" skill as well as "Cut Behind &
          Turn", which are probably the 2 most important skills for a winger.
        </p>
        <p>
          His good weak foot usage makes him a good option for either side, and
          he has unusual Kicking Power for a player of his position.
        </p>
        <p>
          Pavón plays in the MLS so he is a free agent in PES, but you can scout
          him easily by combining "Free Agent", "Argentina" and one of these
          scouts:
        </p>
        <ul className="grid-list">
          <li>RWF</li>
          <li>U-24</li>
          <li>Curl</li>
          <li>Speed</li>
          <li>Track Back</li>
          <li>Long Ranger</li>
          <li>Knuckle Shot</li>
          <li>Rising Shots</li>
          <li>Prolific Winger</li>
          <li>Speeding Bullet</li>
          <li>Fighting Spirit</li>
          <li>Long Range Drive</li>
          <li>Pinpoint Crossing</li>
          <li>Cut Behind & Turn</li>
        </ul>
        <h2 id="bestsilverball">Best Silver Ball Players</h2>
        <h3 className="bullet">Matteo Guendouzi (Arsenal)</h3>
        <TacticalImg
          src={`m-guendouzi.jpg`}
          alt={`Matteo Guendouzi in PES 2020 myClub`}
        />
        <p>
          Guendouzi is my favorite defensive midfielder in the whole game, and
          as long as he's on C-Condition, I will have him on my 5-star team.
        </p>
        <p>
          His stamina makes sure he will run up and down the whole game,
          appearing often inside the opponent's box.
        </p>
        <p>
          Guendouzi is not only a destroyer in the midfield but also a
          chance-creator. I find his passing to be very good, especially his
          lofted pass.
        </p>
        <p>
          He is so vital to my team that if he is not playing, he is the player
          I miss the most, as I am yet to find a suitable replacement. My
          favorite Silver Ball in the game.
        </p>
        <h3 className="bullet">Vitaliy Mykolenko (Dynamo Kyiv)</h3>
        <TacticalImg
          src={`v-mykolenko.jpg`}
          alt={`Vitaliy Mykolenko in PES 2020 myClub`}
        />
        <p>
          In my opinion, the best LB in the game. Yes, better than Alba, Alex
          Sandro, Robertson or my hero Roberto Carlos.
        </p>
        <p>
          I have mentioned before that I enjoy players with the "Defensive
          Full-back" playing style, and this young guy feels way better than the
          stats suggest. He never lets me down.
        </p>
        <p>
          Even with his average form, I rarely find the need to replace him
          before kick-off, and I always have him in my 5-star team.
        </p>
        <p>
          He is very solid defensively, can keep up with any quick winger and
          can hold his own with the ball at this feet.
        </p>
        <p>
          One cool thing about Mykolenko is that you can train him quickly with
          free scouts - all you need is "Other(Europe)", "LB", and "Ball
          Winning", averaging 3 stars.
        </p>
        <h3 className="bullet">Aaron Wan-Bissaka</h3>
        <TacticalImg
          src={`a-wan-bissaka.jpg`}
          alt={`Aaron Wan-Bissaka in PES 2020 myClub`}
        />
        <p>
          My third choice RB in my 5-star team, after Joe Gomez and Éder
          Militão, also listed above. Bissaka has all the important stats on
          paper - solid defending and pace - but since I like my RB's to stay
          back, I need to spend one Advanced Instruction ("Defensive") on him,
          as he loves to join the attack.
        </p>
        <p>
          A great player to have around if you need someone more
          offensive-minded, and a player who has had his fair share of good
          matches so far this season (ie, good Live Condition ratings).
        </p>
        <h3 className="bullet">Henry Onyekuru (Monaco / Galatasaray)</h3>
        <TacticalImg
          src={`h-onyekuru.jpg`}
          alt={`Henry Onyekuru in PES 2020 myClub`}
        />
        <p>
          One of my favorite players in PES 2019, Onyekuru in PES 2020 is the
          definition of an overpowered winger - pace, dribbling, offensive
          awareness, and crazy finishing.
        </p>
        <p>
          If you add a skill like "Double Touch", he can fit any 5-star team, at
          least for 60 minutes. He hasn't been performing that well in real
          life, but his high form in the game should ensure he will be available
          for most matches with a good arrow.
        </p>
        <p>
          You can scout Onyekuru for free by combining "Africa", "LMF", and
          "Offensive Awareness" and averaging 3 stars.
        </p>
        <h3 className="bullet">Alphonso Davies (FC Bayern Munich)</h3>
        <TacticalImg
          src={`a-davies.jpg`}
          alt={`Alphonso Davies in PES 2020 myClub`}
        />
        <p>
          This young Canadian winger has been making an impact in the
          Bundesliga. He has a unique Playing Style for a winger, but don't let
          that deceive you: he is not someone you would want in the middle.
        </p>
        <p>
          Davies is a speedster with decent dribbling. He is so fast that both
          "Speed" and "Acceleration" are maxed out halfway through his training!
          The downside is that he has very low Stamina, so you can't rely on him
          for the full 90 minutes.
        </p>
        <p>
          As a sub, I think he works well, and with Bayern often dominating
          their opposition in the domestic league, it shouldn't be uncommon to
          see this player with "A" or "B" in the weekly Live Condition ratings.
        </p>
        <h3 className="bullet">Rodrygo (Real Madrid)</h3>
        <TacticalImg src={`rodrygo.jpg`} alt={`Rodrygo in PES 2020 myClub`} />
        <p>
          Rodrygo was a popular name for PES 2019 players, while he was still
          playing at Santos. He was upgraded from last year, but he remains a
          Silver Ball player.
        </p>
        <p>
          He is similar to Vinícius Júnior in many aspects: both play in the
          same club, both are Brazilian wingers and they share the "Roaming
          Flank" playing style. But there are differences, of course!
        </p>
        <p>
          Rodrygo has amazing "Speed" and "Dribbling" stats and has far better
          "Finishing" than Vinícius. Despite being great at dribbling and coming
          from Brazil, he is not a trickster -he only possesses 2 Player Skills:
          "Flip Flap" and "Scissors Feint".
        </p>
        <p>
          If trained to his maximum potential, he develops some insane stats for
          a Silver Ball player. And with Real Madrid featured often in Matchday,
          he isn't too hard to train.
        </p>
        <p>
          You just need to combine the Real Madrid scout and "Speed", "Ball
          Control" or "LWF" (which are free) to have him (averaging 3 stars).
        </p>
        <h3 className="bullet">Phil Foden (Manchester City)</h3>
        <TacticalImg
          src={`p-foden.jpg`}
          alt={`Phil Foden in PES 2020 myClub`}
        />
        <p>
          Phil Foden is a talented midfielder to have, especially if you enjoy
          players with the "One-touch Pass" skill.
        </p>
        <p>
          He possesses good dribbling stats, but apart from the "Cut Behind &
          Turn" skill, don't expect to pull off fancy tricks with him. I feel he
          is better at passing, so he can be a good solution if you enjoy
          build-up plays.
        </p>
        <p>
          At 19 years of age, Foden has many levels to keep you busy. His
          overall rating reaches 90.
        </p>
        <h3 className="bullet">Rafael Leão (AC Milan)</h3>
        <TacticalImg
          src={`rafael-leao.jpg`}
          alt={`Rafael Leão in PES 2020 myClub`}
        />
        <p>
          A promising Portuguese CF who is quite tall, strong and unusually
          quick. I still have a long way to go in terms of training Rafael, but
          I have been very impressed with his positioning and clinical
          finishing.
        </p>
        <p>
          He has the "Super-sub" skill, which can make the difference if you
          need to try something different to attempt a comeback or break the
          deadlock.
        </p>
        <p>If you fully train him, his overall level reaches 89.</p>
        <h2 id="bestbronzeball">Best Bronze Ball Players</h2>
        <h3 className="bullet">Takefusa Kubo (RCD Mallorca)</h3>
        <TacticalImg
          src={`t-kubo.jpg`}
          alt={`Takefusa Kubo in PES 2020 myClub`}
        />
        <p>
          Konami tends to favor featured teams, and Mallorca is a featured club
          in PES 2020. Kubo is arguably the team's superstar, and he feels like
          a poor man's Messi whenever I use him (that's a compliment!).
        </p>
        <p>
          Kubo has many useful skills, such as "Step on Skill Control" for quick
          turns and in my opinion the best skill in PES 2020, the "Double
          Touch". He is better suited as an AMF (where that "Creative Playmaker"
          playing style shines).
        </p>
        <p>
          He does have a downside: his poor form shows he won't be very
          consistent on C-condition weeks. His weak foot never bored me.
        </p>
        <p>
          Kubo is still very young, so if you have the will and discipline to
          train him all the way to the max, his overall rating will reach 90,
          highlighting his great dribbling stats.
        </p>
        <p>
          You can scout him for free by combining "Spanish League",
          "Asia-Oceania", and "Offensive Awareness", averaging 2 stars.
        </p>
        <h3 className="bullet">Marcos Paulo (Fluminense)</h3>
        <TacticalImg
          src={`marcos-paulo.jpg`}
          alt={`Marcos Paulo in PES 2020 myClub`}
        />
        <p>
          A young Portuguese forward that you can easily train, with great
          potential (88 is the maximum overall level, which is great for a
          Bronze Ball player).
        </p>
        <p>
          Marcos Paulo has a good mix of speed and physicality, and decent
          dribbling and shooting stats.
        </p>
        <p>
          You can use many different Free Scout combinations to have him; just
          combine "Brazilian League", "Europe", and any of these, averaging 2
          stars:
        </p>
        <ul className="grid-list">
          <li>CF</li>
          <li>U-24</li>
          <li>Speed</li>
          <li>Finishing</li>
          <li>Acceleration</li>
          <li>185cm or more</li>
        </ul>
        <p>
          Like Kubo, his poor form is arguably the biggest downside. He doesn't
          have many skills either.
        </p>
        <h3 className="bullet">Mason Greenwood (Manchester United)</h3>
        <TacticalImg
          src={`m-greenwood.jpg`}
          alt={`M. Greenwood in PES 2020 myClub`}
        />
        <p>
          Ole Gunnar Solskjaer has praised Greenwood's natural finishing in the
          past, and you will probably agree after playing a couple of games with
          this boy.
        </p>
        <p>
          You won't find many players with 4/4 on both "Weak Foot Usage" and
          "Weak Foot Accuracy"; this means that while controlling Greenwood, you
          won't need to think about the best foot to finalize that play.
        </p>
        <p>
          Speed and Balance are his main assets, but he has very balanced stats
          when it comes to shooting and dribbling, and many important skills
          such as "Chip Shot Control" when facing the keeper or "Cut Behind &
          Turn" for quick turns.
        </p>
        <p>
          Manchester United is often featured in Matchday, so it shouldn't be a
          problem collecting scouts to train him either.
        </p>
        <h3 className="bullet">Ansu Fati (FC Barcelona)</h3>
        <TacticalImg
          src={`ansu-fati.jpg`}
          alt={`Ansu Fati in PES 2020 myClub`}
        />
        <p>
          Fati is super young (17 years of age), which explains why he has 75
          levels. If you're brave enough to fully train him, you will end up
          with an 89-rated bronze ball prolific winger.
        </p>
        <p>
          He has been performing well in real-life, so you may find him on
          A-Condition every now and then as he has been getting some playtime
          this season.
        </p>
        <p>
          He reaches 99 on both Speed and Acceleration, so if you enjoyed
          Babangida back in the day, you will have fun running around with this
          kid!
        </p>
        <h3 className="bullet">Gabriel Martinelli (Arsenal)</h3>
        <TacticalImg
          src={`martinelli.jpg`}
          alt={`Gabriel Martinelli in PES 2020 myClub`}
        />
        <p>
          A youngster who has been receiving some playtime this season at
          Arsenal, and that you can scout for free by combining "English
          League", "N/S American" and "LWF", averaging 2 stars.
        </p>
        <p>
          He has great pace and balance, making it perfect to play on the wing,
          and can chip very well when facing the keeper.
        </p>
      </div>
    </Layout>
  )
}

export default bestPlayers
